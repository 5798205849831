@tailwind base;
@tailwind components;
@tailwind utilities;

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --main-bg-color: #22212e;
}
body {
  margin-inline: auto;
  overflow: hidden;
  max-width: 720px;
  -webkit-user-select: none;
          user-select: none;
  background-color: #22212e;
  background-color: var(--main-bg-color);
}

.font-bold{
  font-family: "'GE Dinkum Medium'";
}
.font-light{
  font-family: "'BoutrosMBCDinkum Medium'";
}
 
.item-card {
  padding: 15px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow:   0 0 10px #2d303e26;
}

.item-card-image-container {
  display: flex ;
  flex-direction: column;
}

.item-card img  {
  width: 140px;
  object-fit: cover;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}

.item-card .resever  {
  height: 100px;
  object-fit: cover;
  border-radius: 20px ;
}

.item-card p {
    font-family: "'GE Dinkum Medium'";
}

/*TamplateTwo  */
.item-card-2 {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow:   0 0 10px #2d303e26;
}

.item-card-2-image-container {
  display: flex ;
  flex-direction: column;
}

.item-card-2 img  {
  max-width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  margin-bottom: 0%;
  margin-inline:auto;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
.chosen-t2 img  {
  max-width: 80%;
  margin-top: 20%;
  border-radius: 50%;
}

.amount-label {
  visibility: hidden;
}
.chosen-t2 .amount-label {
  visibility: visible;
}
.price{
  transition: all 300ms ease-in-out;
}
.chosen-t2 .price {
  top: -30px;
}

/* font-family: "'BoutrosMBCDinkum Medium'"; */
.item-card-2 p {
    font-family: "'GE Dinkum Medium'";
}
.categories-card {
  padding: 10px;
  border-radius: 30px;
  transition: all 300ms ease-in-out;
  box-shadow:   0 0 10px #2d303e26;
}


.categories-card img  {
  object-fit: contain;
  width: 150px;
  max-height: 100px;
  border-radius: 20px ;
  transition: all 300ms ease-in-out;
}
 
.categories-card p {
    font-family: "'GE Dinkum Medium'";
}
.scroller::-webkit-scrollbar {
    height: 5px;
    width: 5px;
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
    margin: 10px;
}
.scroller::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: #3f3c4daa;
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
}
.scroller::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}
.scrller-container {
    overflow-y: auto;
    max-height: 100vh;
    padding: 10px;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.Grid {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    grid-template-columns: repeat(2, minmax(auto, 1fr));
}

@media screen and (min-width: 720px) {
    .Grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
}

@media screen and (min-width: 1000px) {
    .Grid {
        grid-template-columns: repeat(3, minmax(auto, 1fr));
        grid-gap: 15px;
    }
}

.larg-grid {
    display: grid;
    grid-gap: 10px;
    padding-inline: 10px;
    grid-template-columns: repeat(1, minmax(auto, 1fr));
}

@media screen and (min-width: 720px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 10px;
    }
}

/* @media screen and (min-width: 1200px) {
    .larg-grid {
        grid-template-columns: repeat(2, minmax(auto, 1fr));
        grid-gap: 15px;
    }
} */


.icon{
    object-fit: cover;
}
 /* transition */
.transition-0{
    transition:none;
  }
  .transition-1{
    transition: all 100ms ease-in-out;
  }
  .transition-2{
    transition: all 200ms ease-in-out;
  }
  .transition-3{
    transition: all 200ms ease-in-out;
  }
  .transition-4{
    transition: all 400ms ease-in-out;
  }
  .transition-5{
    transition: all 500ms ease-in-out;
  }
  /*  */
  /*  */
  .fade-svg-btn{
    -webkit-animation: fade-svg-btn-frames 200ms ease-out ;
            animation: fade-svg-btn-frames 200ms ease-out ;
    margin-right: 0px;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: 1;
    position: relative;
  }
  
  @-webkit-keyframes fade-svg-btn-frames{
    0%{
      opacity:0.3;
      right: -100px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  
  @keyframes fade-svg-btn-frames{
    0%{
      opacity:0.3;
      right: -100px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  .fade-qantity-btn{
    -webkit-animation: fade-qantity-btn-frames 200ms ease-out ;
            animation: fade-qantity-btn-frames 200ms ease-out ;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    z-index: 2;
    position: relative;

  }
  
  @-webkit-keyframes fade-qantity-btn-frames{
    0%{
      opacity:0.3;
      right: -40px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
}
  
  @keyframes fade-qantity-btn-frames{
    0%{
      opacity:0.3;
      right: -40px;
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
    100%{
      opacity:1;
      right: 0px;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
  }
}
  
  /*  */
.row-center { display: flex; flex-direction: row; align-items: center;}
.col-center {display: flex;flex-direction: column; align-items: center;}
.col {display: flex;flex-direction: column;}
.row { display: flex; flex-direction: row;}
.wrap { display: flex; flex-wrap:wrap;}


.overflow-hidden { overflow: hidden;}
.overflow-auto { overflow: auto;}
.overflow-x-auto { overflow-x:  auto;}
.overflow-x-hidden { overflow-x:  hidden;}
.overflow-y-auto { overflow-y:  auto;}
.overflow-y-hidden { overflow-y:  hidden;}
.hidden{ display: none;}
.flex-grow{flex-grow: 1;}
.flex-1{flex: 1 1;}

.shdow-0{box-shadow: 0px 0px 10px rgba(0,0,0,0.20); }
.shdow-1{box-shadow: 0px 0px 10px rgba(0,0,0,0.30); }
.shdow-2{box-shadow: 0px 0px 10px rgba(0,0,0,0.40); }
.shdow-3{box-shadow: 0px 0px 10px rgba(0,0,0,0.50); }
.shdow-4{box-shadow: 0px 0px 10px rgba(0,0,0,0.60); }

.rounded-1 {border-radius: 5px;}
.rounded-2 {border-radius: 10px;}
.rounded-3 {border-radius: 15px;}
.rounded-4 {border-radius: 20px;}
.rounded-5 {border-radius: 25px;}
.rounded-full{border-radius: 50%;}

.fixed {position:fixed;}
.sticky {position:-webkit-sticky;position:sticky;}
.absolute {position:absolute;}
.relative {position: relative;}

.justify-around{justify-content: space-around;}
.justify-between{justify-content: space-between;}
.justify-center{justify-content: center;}

.items-center{align-items: center;}
.items-start{align-items: flex-start;}
.items-end{align-items: flex-end;}

.text-center{text-align: center;}
.text-left{text-align:left ;}
.text-start{text-align:start ;}

.p-1{padding: 4px;}
.p-2{padding: 6px;}
.p-3{padding: 8px;}
.p-4{padding: 10px;}
.p-5{padding: 12px;}
.p-6{padding: 14px;}
.p-7{padding: 16px;}
.p-8{padding: 18px;}
.p-9{padding: 24px;}
.p-10{padding: 22px;}

.py-1{padding-block: 4px;}
.py-2{padding-block: 6px;}
.py-3{padding-block: 8px;}
.py-4{padding-block: 10px;}
.py-5{padding-block: 12px;}
.py-6{padding-block: 14px;}
.py-7{padding-block: 16px;}
.py-8{padding-block: 18px;}
.py-9{padding-block: 24px;}
.py-10{padding-block: 28px;}

.px-1{padding-inline: 4px;}
.px-2{padding-inline: 6px;}
.px-3{padding-inline: 8px;}
.px-4{padding-inline: 10px;}
.px-5{padding-inline: 12px;}
.px-6{padding-inline: 14px;}
.px-7{padding-inline: 16px;}
.px-8{padding-inline: 18px;}
.px-9{padding-inline: 24px;}
.px-10{padding-inline: 28px;}

.m-1{margin: 4px;}
.m-2{margin: 6px;}
.m-3{margin: 8px;}
.m-4{margin: 10px;}
.m-5{margin: 12px;}
.m-6{margin: 14px;}
.m-7{margin: 16px;}
.m-8{margin: 18px;}
.m-9{margin: 24px;}
.m-10{margin: 28px;}
.m-auto{margin:auto;}

.my-1{margin-block: 4px ;}
.my-2{margin-block: 6px ;}
.my-3{margin-block: 8px ;}
.my-4{margin-block: 10px ;}
.my-5{margin-block: 12px ;}
.my-6{margin-block: 14px ;}
.my-7{margin-block: 16px ;}
.my-8{margin-block: 18px ;}
.my-9{margin-block: 24px ;}
.my-10{margin-block: 28px ;}
.my-auto{margin-block:auto;}

.mx-1{margin-inline:4px;}
.mx-2{margin-inline:6px;}
.mx-3{margin-inline:8px;}
.mx-4{margin-inline:10px;}
.mx-5{margin-inline:12px;}
.mx-6{margin-inline:14px;}
.mx-7{margin-inline:16px;}
.mx-8{margin-inline:18px;}
.mx-9{margin-inline:24px;}
.mx-10{margin-inline:28px;}
.mx-auto{margin-inline:auto;}
 
.gap-1{grid-gap: 4px;gap: 4px;}
.gap-2{grid-gap: 6px;gap: 6px;}
.gap-3{grid-gap: 8px;gap: 8px;}
.gap-4{grid-gap: 10px;gap: 10px;}
.gap-5{grid-gap: 12px;gap: 12px;}
.gap-6{grid-gap: 14px;gap: 14px;}
.gap-7{grid-gap: 16px;gap: 16px;}
.gap-8{grid-gap: 18px;gap: 18px;}
.gap-9{grid-gap: 24px;gap: 24px;}
.gap-10{grid-gap: 28px;gap: 28px;}

.text-xs{font-size: 8px;}
.text-sm{font-size: 10px;}
.text-md{font-size: 12px;}
.text-lg{font-size: 14px;}
.text-xl{font-size: 16px;}
.text-2xl{font-size: 18px;}
.text-3xl{font-size: 20px;}
.text-4xl{font-size: 24px;}
.text-5xl{font-size: 28px;}
.text-6xl{font-size: 34px;}

.z-1{z-index: 1;}
.z-2{z-index: 2;}
.z-3{z-index: 3;}
.z-4{z-index: 4;}
.z-5{z-index: 5;}
.z-6{z-index: 6;}
.z-7{z-index: 7;}
.z-8{z-index: 8;}
.z-9{z-index: 9;}
.z-10{z-index: 10;}

.backdrop-blur-none{-webkit-backdrop-filter: blur(0);backdrop-filter: blur(0);}
.backdrop-blur-sm{-webkit-backdrop-filter: blur(4px);backdrop-filter: blur(4px);}
.backdrop-blur-md{-webkit-backdrop-filter: blur(8px);backdrop-filter: blur(8px);}
.backdrop-blur-lg{-webkit-backdrop-filter: blur(16px);backdrop-filter: blur(16px);}
.backdrop-blur-xl{-webkit-backdrop-filter: blur(24px);backdrop-filter: blur(24px);}
.backdrop-blur-2xl{-webkit-backdrop-filter: blur(40px);backdrop-filter: blur(40px);}
.backdrop-blur-3xl{-webkit-backdrop-filter: blur(64px);backdrop-filter: blur(64px);}

.inset-0{inset: 0;}
.inset-1{inset: 4px;}
.inset-2{inset: 6px;}
.inset-3{inset: 8px;}
.inset-4{inset: 10px;}
.inset-5{inset: 12px;}
.inset-6{inset: 14px;}
.inset-7{inset: 16px;}
.inset-8{inset: 18px;}
.inset-9{inset: 24px;}
.inset-10{inset: 28px;}

.max-w-xs{
    max-width: 150px;
}
.max-w-sm{
    max-width: 250px;
}
.max-w-md{
    max-width: 420px;
}
.max-w-lg{
    max-width: 768px;
}
.max-w-xl{
    max-width: 1024px;
}
.max-w-2xl{
    max-width: 1280px;
}
.max-w-3xl{
    max-width: 1536px;
}
.max-w-4xl{
    max-width: 1920px;
}
.h-full{
    height:90vh
}


input {border: none; outline-width: 0px;  }
.pointer {cursor: pointer; }

.top-0 {top: 0; }
.left-0 {left: 0; }
.right-0 {right: 0; }
.bottom-0 {bottom: 0; }

.top-1 {top: 45px; }
.left-1 {left: 45px; }
.right-1 {right: 45px; }
.bottom-1 {bottom: 45px; }

.top-2 {top: 90px; }
.left-2 {left: 90px; }
.right-2 {right: 90px; }
.bottom-2 {bottom: 90px; }

.col-span-full{grid-column: 1 / -1;} 

.background {background-color: #f5f5f5;}
.dark .background {background-color: #1f1d2b;}


.item-icon-bg {fill: #efeff0;}
.dark .item-icon-bg {fill: #5c5e69;}


.item-defult-icon-bg {background-color: #f4f4f5;}
.dark .item-defult-icon-bg {background-color:  #3c3f4c;}


.carbonic-card{background-color: #FFFFFF;} 
.dark .carbonic-card{background-color: #2d303e;} 

.carbonic-card-1{background-color: #FBFBFB;} 
.dark .carbonic-card-1{background-color: #353848;} 

.carbonic-card-2{background-color: #FFFFFF;} 
.dark .carbonic-card-2{background-color: #393c4a;} 

.carbonic-card-3{background-color: #FBFBFB;} 
.dark .carbonic-card-3{background-color: #292a39;} 

.carbonic-card-4{background-color: #F0F0F0;} 
.dark .carbonic-card-4{background-color: #393c4a;} 

.carbonic-card-5{background-color: #f6f6f6;} 
.dark .carbonic-card-5{background-color: #3b3e4c;} 

.carbonic-card-6{background-color: #eaeaeb;} 
.dark .carbonic-card-6{background-color: #eaeaeb;} 

.carbonic-card-7{background-color: #fbfbfb;} 
.dark .carbonic-card-7{background-color: #3c3f4c;} 

.carbonic-card-8{background-color: #f5f5f5;} 
.dark .carbonic-card-8{background-color: #22212e;} 

.blur-card{background-color: #f5f5f555;} 
.dark .blur-card{background-color: #22212e55;} 

.blur-card-1{background-color: #63cfc955;} 
.dark .blur-card-1{background-color: #63cfc955;} 


.white-card{background-color: #FFFFFF;}

 
.search-bg {background-color: #ffffff;}
.dark .search-bg {background-color: #3c3f4c;}

.account-check-bg {background-color: #ffffff;}
.dark .account-check-bg {background-color: #3c3f4c;}


.carbonic {color: #1f1d2b;}
.dark .carbonic {color: #ffffff;}


.carbonic-1 {color: #c3c3c6;}
.dark .carbonic-1 {color: #6c6e78;}


.carbonic-2 {color: #c4c4c7;}
.dark .carbonic-2 {color: #9e9fa6;}


.carbonic-3 {/* TextGray */color: #c4c4c7;}
.dark .carbonic-3 {color: #767982;}


.carbonic-4 {color: #bebec1;}
.dark .carbonic-4 {color: #767982;}


.check-bg {background-color: #f3f3f1;}
.dark .check-bg {background-color: #4c4e5a;}

.compo-bg {background:rgba(196, 196, 199,0.85);}
.dark .compo-bg {background:rgba(31, 29, 43,0.85);}




.bg-logout {background-color: #f4e2e3;}

.dark .bg-logout {background-color: #36232f;}


.menu-label {background-color: #fcfcfc;}
.dark .menu-label {background-color: #2d2c39;}

.red-card {background-color: #dd3643;}
.green-bg {background-color: #56e181;}
.check-bg {background-color: #e9e9eb;}
.white-card {background-color: #ffffff;}
.dark .check-bg {background-color: #494c5a;}




.orange-card {background-color: #f48114;}
.orange-card-1 {background-color: #ffa44e;}
.orange-card-2 {background-color: #f9c089;}
.dark .orange-card-1 {background-color: #905929;}

.dark .orange-card-2 {background-color: #ffb938;}

.cyan-card {background-color: #63cfc9;}

.green-card {background-color: #56e181;}

.orange-text {color: #f48114;}
.orange-text-2 {color: #ffa44e;}
.orange-text-3 {color: #f9c089;}
.dark .orange-text-3 {color: #905929;}

.dark .orange-text-4 {color: #ffb938;}

.cyan-text {color: #63cfc9;}
.green-text {color: #56e181;}
.red-text {color: #dd3643;}
.white-text {color: #fff;}

.red-text-1 {color: #ec4245;}

.dark .red-text-1 {color: #ffffff;}


/* .green-text {color: #1bd69a;} */
.menu-phone {color: #6b6d78;}
/* MenuPhone */
.dark .menu-phone {color: #c4c4c7;}


/* .shadow-1 {box-shadow: 0px 0px 10px rgba(60, 63, 76, 0.15);} */
.shadow-1 {box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);}


/* .dark .shadow-1 {box-shadow: unset;} */


/* -///////////////////- */
/* -///////////////////- */
/* tincture */
.tint {fill: #c4c4c7;}

.dark .tint {fill: #6c6f78;}


.tint-stroke {stroke: #c4c4c7;}
.dark .tint-stroke {stroke: #6c6f78;}


.tint-1 {fill: #c3c3c6;}
.dark .tint-1 {fill: #72747f;}


.tint-2 {fill: #b8b7bc;}
.dark .tint-2 {fill: #72747f;}


.tint-item {fill: #d5d6d9;}
.dark .tint-item {fill: #6d6f78;}


.tint-menu {fill: #b8b7bc;}
.dark .tint-menu {fill: #96969c;}


.tint-tab {fill: #bebec1;}
.dark .tint-tab {fill: #62606b;}

.active .tint-tab {fill: #dd3643;}

.tint-main-tab {fill: #fff;}
.dark .tint-main-tab {fill: #8b8a91;}

.active .tint-main-tab {fill: #fff;}

.tint-squer {border: solid 2px #d9d9da;}
.dark .tint-squer {border: solid 2px #767982;} 



/* Accounts */
.accounts-business-svg {fill: #efeff0;}
.dark .accounts-business-svg {fill: #5c5e69;}


/*  */

/* Accounts */

/* -///////////////////- */
/* -///////////////////- */

.border-left {border-left: solid 1px #ebebeb;}

.dark .border-left {border-left: solid 1px #1f1d2b;}


.border-top {border-top: solid 2px #ebebeb;}

.dark .border-top {border-top: solid 2px #1f1d2b;}

.border-bottom {border-bottom: solid 2px #ebebeb;}

.dark .border-bottom {border-bottom: solid 2px #1f1d2b;}


.menu-profile-border {border: solid 2px #dcdcde;}
/* MenuImageBorder */
.dark .menu-profile-border {border: solid 2px #3a3a46;}


/* -///////////////////- */
/* -///////////////////- */

.backButton {background-color: #c6c6ca;padding: 6px 10px;}

.dark .backButton {background-color: #8f8e95;padding: 6px 10px;}


/* -///////////////////- */

/*  *** *** *** ***  */
/*  *** *** *** ***  */
.un-active-mode {background: linear-gradient(90deg, #bebec1, #bebec1);}
.dark .un-active-mode {background: linear-gradient(90deg, #65636d, #65636d);}


.acitve-mode {background: linear-gradient(90deg, #d0394f, #e83439);color: #fff;}
.dark .acitve-mode {background: linear-gradient(90deg, #d0394f, #e83439);color: #fff;}

.acitve-mode-shadow {box-shadow: 0px 0px 25px #e7353c;/* rgba(231, 53, 60, 1); */}

.mode-svg {fill: #fff0;}

.acitve-mode .mode-svg {fill: #fff;}

.item-quantity {left: -10px;fill: #fff;display: flex;padding: 0 4px;position: absolute;border-radius: 25px;flex-direction: row;align-items: center;background-color: #f48114;border: solid 2px #d17117;
}

/*  *** *** *** ***  */
 
.carbonic-card-svg {fill: #ffffff;}
.dark .carbonic-card-svg {fill: #2d303e;}


.invoice-top {background-color: #f5f5f5;}
.dark .invoice-top {background-color: #3d404e;}


.invoice-shape {background-color: #e6e6e6;}

.dark .invoice-shape {background-color: #393c4a;}

.invoice-shape-svg {fill: #e6e6e6;}

.dark .invoice-shape-svg {fill: #393c4a;}


.invoice-border-svg {fill: #ecebe8;}

.dark .invoice-border-svg {fill: #494c5a;}


.invoice-number-svg {fill: #c4c4c7;}
.dark .invoice-number-svg {fill: #4d505c;}


.invoice-bg-date {background-color: #fbfbfb;border-top: solid 1px #ebebeb;}
.dark .invoice-bg-date {background-color: #292a39;border-top: solid 1px #393d4d;}


.invoice-bg-date-2 {background-color: #fbfbfb;border: solid 1px #ededee;}
.dark .invoice-bg-date-2 {background-color: #383b48;border: solid 1px #4c4f5b;}


.invoice-quantity {background-color: #c4c4c7;border: solid 2px #b9b9bb;fill: #fff;}

.dark .invoice-quantity {background-color: #484a57;border: solid 2px #3f414e;fill: #767982;}


.invoice-bg-header {background-color: #faf9f7;border-bottom: solid 1px #e7e6de;}
.dark .invoice-bg-header {background-color: #393c4a;border-bottom: solid 1px #4c4f5b;}


.invoice-bg-items {background-color: #ffffff;}
.dark .invoice-bg-items {background-color: #313442;}


.tint-price-svg {fill: #c4c4c7;}

.dark .tint-price-svg {fill: #6c6e78;}


/* Invoices */

.tab-container-bg {background-color: #f5f5f550;}
.dark .tab-container-bg {background-color: #1f1d2b50;}


/* Svgs */
.text-input{border-radius : 14px;padding: 10px;fill:#c4c4c7 ;background-color: #ffffff;border : solid 2px #efeff0;}
.dark .text-input{fill:#767982 ;background-color: #3c3f4c;border : solid 2px #3c3f4c;}


.text-input input{background-color: #ffffff;}
.dark .text-input input{background-color: #3c3f4c;}


.dark .icon-card svg{fill: #767982;stroke: #494c5a;}

.light .icon-card svg{fill: #ecebe8;stroke: #bebec1;}

.select-icon{fill: #1f1d2b;}
.dark .select-icon{fill: #9e9fa6;}

.border-none{border: none;}

.svg-icon-with-bg{padding:10px;width: 40px;fill: #bebec1;border-radius: 10px;background-color: #FFFFFF;box-shadow: 0px 0px 10px rgb(45, 48, 62,0.15);}
.dark .svg-icon-with-bg{background-color: #2d303e;}


.svg-icon{fill: #bebec1; height: 36px;}
.svg-icon .border{fill: #ecebe8;}
.container-checked .svg-icon{fill: #f1bac4;}
.container-checked .svg-icon .border{fill: #e87f90;}
.container-checked .carbonic-3{color: #f0b9c5;}




.not-fount .svg-icon{fill: #DCDCDD;background-color:#f0f1f1 ;height: 36px;border-radius: 10px;}
.not-fount .svg-icon .border{fill: #f0f1f1;}

.not-fount p{color: #D9DDDD;}

/* Svgs */

.read-only-bg{border-radius : 14px;padding: 10px;fill:#c4c4c7 ;background-color: #e8e8e9;border : solid 2px #efeff0;}
.read-only-bg input{background-color: #e8e8e9;}

  .lds-ripple {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;
      min-height: 50vh;
  }

  .lds-ripple div {
      opacity: 1;
      border-radius: 50%;
      position: absolute;
      border: 4px solid #22212e;
      -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
              animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

   .dark .lds-ripple div {
      border: 4px solid #fff;
   }

  .lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
  }
  @-webkit-keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      width: 0;
      height: 0;
      opacity: 1;
    }
 
    100% {
      width: 102px;
      height: 102px;
      opacity: 0;
    }
  }
  @keyframes lds-ripple {
    0% {
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      width: 0;
      height: 0;
      opacity: 1;
    }
 
    100% {
      width: 102px;
      height: 102px;
      opacity: 0;
    }
  }
  
